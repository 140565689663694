<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 background-transparent"
      :items-per-page="25"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <div class="d-inline-flex justify-space-between align-center pr-2 mb-2" style="width: 100%">
    
          <!-- Left-aligned content -->
          <div class="d-inline-flex align-center">
            <v-switch 
              v-model="activeOnly" 
              data-cy="switch-active-only" 
              :label="$lang.labels.activeOnly" 
              class="pl-2 pr-3"
              inset
              dense
            ></v-switch>
            <v-text-field
              v-model="search"
              v-debounce:500="handleDebounceSearch"
              class="custom-search-perm"
              outlined
              hide-details
              clearable
              dense
              :label="$lang.labels.searchByName"
              data-cy="search-by-name"
            ></v-text-field>
            <v-select
              v-model="permissionType"
              class="ml-2 custom-search-perm"
              hide-details
              :items="typesFormatted"
              :label="$lang.labels.permissionType"
              outlined
              dense
              clearable
            ></v-select>
          </div>

          <!-- Right-aligned buttons -->
          <div class="d-inline-flex align-center ml-auto">
            <v-btn
              text
              light
              class="ml-2"
              color="primary"
              @click="$emit('fetchPlugins', { options: options, search: search && search.length > 1 ? search : '', permissionType: permissionType, resetPage: true })"
            >
              <v-icon dense small>mdi-refresh</v-icon>
            </v-btn>
            <v-btn 
              v-if="roles.includes('PLUGIN_CREATOR')" 
              color="accent" 
              class="color-accent-text ml-2" 
              :to="{ name: 'pluginCreate' }"
            >
              {{ $lang.actions.create }}
            </v-btn>
          </div>
    
        </div>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.modifiedOn="{ item }">
        <div>{{ item.modifiedOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.status="{ item }">
        <div class="custom-active-deactive">
          <p :class="{'custom-active': item.status === 'ACTIVE', 'custom-deactivated': item.status !== 'ACTIVE', 'm-0 p-0 mb-0': true }">
            {{ $lang.status[item.status] }}
          </p>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-inline-flex justify-end" >
          <!-- Separate Edit Button -->
          <v-btn
            text
            color="primary"
            :to="{ name: 'pluginEdit', params: { id: item.id } }"
          >
            <v-icon small class="mr-1">mdi-lead-pencil</v-icon>
            {{ item.isSystem ? $lang.actions.view : $lang.actions.edit }}
          </v-btn>

          <!-- Button with mdi-dots-vertical Icon -->
          <v-menu class="custom-edit-menu" offset-y nudge-left="95" transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                variant="text"
                icon
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <!-- Menu with actions -->
            <v-card class="pr-2 pl-1" style="border: #CBD5E1 1px solid ;">
              <v-list dense>
                <!-- Deactivate/Activate button -->
                <v-list-item
                  link
                  class="text--secondary font-weight-light"
                  @click="$emit('changeStatus', {id: item.id, status: item.status})"
                >
                  {{ item.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                </v-list-item>

                <!-- Copy Create button -->
                <v-list-item
                  v-if="roles.includes('PLUGIN_CREATOR')" 
                  link
                  class="text--secondary font-weight-light"
                  @click="$emit('copyCreate', item)"
                >
                  {{ $lang.actions.copyCreate }}
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '@/main'

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lock: true,
      activeOnly: true,
      search: '',
      permissionType: '',
      options: {},
      totalItems: 0,
      headers: [
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: this.$lang.labels.status, value: 'status', sortable: true },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      roles: [],
      permissionTypes: ['EDIT', 'USE', 'VIEW']
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings']),
    typesFormatted () {
      return this.permissionTypes.map((item) => {
        return { text: this.$lang.status[item], value: item }
      })
    }
  },
  watch: {
    options: {
      handler () {
        if (!this.lock) this.$emit('fetchPlugins', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', permissionType: this.permissionType })
        this.savePreFill()
      },
      deep: true
    },
    activeOnly: {
      handler (val) {
        if (!this.lock) this.$emit('activeOnly', { activeOnly: val, options: { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', permissionType: this.permissionType, resetPage: true } })
        this.savePreFill()
        this.options.page = 1
      }
    },
    permissionType: {
      handler () {
        if (!this.lock) this.$emit('fetchPlugins', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', permissionType: this.permissionType, resetPage: true })
        this.savePreFill()
        this.options.page = 1
      }
    },
    search: {
      handler (val) {
        if (!this.lock && (val === null || val === '')) this.$emit('fetchPlugins', { options: this.options, search: '', permissionType: this.permissionType, resetPage: true })
        this.savePreFill()
        this.options.page = 1
      }
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.$emit('fetchPlugins', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', permissionType: this.permissionType, resetPage: true })
      this.options.page = 1
    })
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    if (this.userSettings.display.showModifiedOn) {
      this.headers.splice(1, 0, { text: this.$lang.labels.modifiedOn, value: 'modifiedOn' })
    }

    if (this.userSettings.display.showCreatedOn) {
      this.headers.splice(1, 0, { text: this.$lang.labels.createdOn, value: 'createdOn' })
    }

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id' })
    }

    if (localStorage.preFillData) {
      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.pluginList) {
        this.search = preFill.pluginList.search
        this.activeOnly = preFill.pluginList.activeOnly
        this.permissionType = preFill.pluginList.permissionType
        this.options = preFill.pluginList.options

        this.$emit('activeOnly', { activeOnly: this.activeOnly, options: { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', permissionType: this.permissionType, resetPage: true } })
      } else {
        this.$emit('fetchPlugins', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', permissionType: this.permissionType, resetPage: true })
      }
    } else {
      this.$emit('fetchPlugins', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', permissionType: this.permissionType, resetPage: true })
    }
    setTimeout(() => this.lock = false, 100)
    this.options.page = 1
  },
  methods: {
    handleDebounceSearch(val, event) {
      this.search = val
      if (!this.lock && val && val.length > 1) this.$emit('fetchPlugins', { options: this.options, search: val, permissionType: this.permissionType, resetPage: true })
      if (!this.lock && (val === null || val === '')) return
      this.savePreFill()
      this.options.page = 1
    },
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.pluginList) preFill.pluginList = {
          search: '',
          activeOnly: true,
          permissionType: '',
          options: {
            page: 1,
            itemsPerPage: 25,
            sortBy: [],
            sortDesc: []
          }
        }

        preFill.pluginList.search = this.search
        preFill.pluginList.activeOnly = this.activeOnly
        preFill.pluginList.permissionType = this.permissionType
        preFill.pluginList.options = { ...this.options, page: 1 }

        localStorage.preFillData = JSON.stringify(preFill)
      }
    }
  }
}
</script>
