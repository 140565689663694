<template>
  <v-container fluid>
    <v-data-table
      v-if="finishInit"
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 background-transparent"
      :items-per-page="25"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <v-row no-gutters class="pt-2 pl-2">
          <!-- Left-aligned content -->
          <v-col cols="2">
            <v-text-field
              v-model="search"
              v-debounce:500="handleDebounceSearch"
              outlined
              hide-details
              clearable
              dense
              :label="$lang.labels.searchByName"
              data-cy="search-by-name"
            ></v-text-field>
          </v-col>

          <v-col cols="2">
            <v-autocomplete
              v-model="type"
              hide-details
              outlined
              dense
              clearable
              :items="formattedTypes"
              hide-no-data
              class="ml-2"
              :label="$lang.labels.type"
              prepend-inner-icon="mdi-format-list-bulleted"
            ></v-autocomplete>
          </v-col>

          <v-col cols="2" class="ml-2">
            <v-datetime-picker
              v-model="dateFrom"
              :label="$lang.labels.from"
              no-title
              :text-field-props="{ outlined: true, dense: true }"
            >
              <template v-slot:dateIcon="{}">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template v-slot:timeIcon="{}">
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>

          <v-col cols="2" class="pl-2">
            <v-datetime-picker
              v-model="dateTill"
              :label="$lang.labels.to"
              no-title
              :text-field-props="{ outlined: true, dense: true }"
            >
              <template v-slot:dateIcon="{}">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template v-slot:timeIcon="{}">
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>

          <!-- Right-aligned refresh button -->
          <v-col cols="auto" class="ml-auto text-right">
            <v-btn
              text
              light
              color="primary"
              class="ml-2"
              @click="sendQuery()"
            >
              <v-icon dense small>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.resourceType="{ item }">
        <div>{{ $lang.status[item.resourceType] }}</div>
      </template>

      <template v-slot:item.userId="{ item }">
        <div>{{ formatUser(item.userId) }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-inline-flex">
          <v-btn 
            text
            color="primary" 
            class="mx-0 button-default-width" 
            :to="{ name: 'historyEdit', params: { id: item.id } }"
          >
            {{ $lang.actions.details }}
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '@/main'
import { definitions } from '@/utils/definitions'
import { fetchUserData } from '@/utils/helpers'
export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lock: true,
      search: '',
      options: {},
      totalItems: 0,
      headers: [
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: false,
          value: 'resourceName'
        },
        {
          text: this.$lang.labels.type,
          align: 'start',
          sortable: false,
          value: 'resourceType'
        },
        {
          text: this.$lang.labels.user,
          align: 'start',
          sortable: false,
          value: 'userId'
        },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      roles: [],
      types: definitions.HistoryDelete.properties.resourceType.enum,
      type: '',
      dateFrom: '',
      dateTill: '',
      finishInit: false
    }
  },
  computed: {
    formattedTypes() {
      return this.types.map((x) => {
        return {
          text: this.$lang.status[x],
          value: x
        }
      })
    },
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings'])
  },
  watch: {
    options: {
      handler () {
        this.sendQuery(false)
      },
      deep: true
    },
    search: {
      handler () {
        this.sendQuery()
      }
    },
    type: {
      handler () {
        this.sendQuery()
      }
    },
    dateFrom: {
      handler () {
        this.sendQuery()
      }
    },
    dateTill: {
      handler () {
        this.sendQuery()
      }
    },
    items: {
      handler () {
        if (this.items && this.items.items && this.items.items.length > 0) this.init()
      },
      deep: true
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.sendQuery()
    })
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    if (this.userSettings.display.showCreatedOn) {
      this.headers.splice(3, 0, { text: this.$lang.labels.createdOn, value: 'createdOn', sortable: false })
    }

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id', sortable: false })
    }

    if (localStorage.preFillData) {
      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.historyList) {
        this.search = preFill.historyList.search
        this.type = preFill.historyList.type
        this.dateFrom = preFill.historyList.dateFrom
        this.dateTill = preFill.historyList.dateTill
      }
    }
    setTimeout(() => {
      this.lock = false
      this.sendQuery()
    }, 100)
  },
  methods: {
    async init() {
      this.finishInit = false
      const allSavedUsers = localStorage.allUsers ? JSON.parse(localStorage.allUsers).map((x) => x.id) : []
      const itemsUsers = this.items.items.map((x) => x.userId)

      const newUsers = allSavedUsers.length > 0 ? itemsUsers.filter((y) => !allSavedUsers.includes(y)) : itemsUsers

      const newUsersUnique = [...new Set(newUsers)]

      if (newUsersUnique.length === 0) {
        this.finishInit = true

        return
      }

      const fetchedUsers = newUsersUnique.map(async (id) => {
        return this.fetchUser(id)
      })

      await Promise.all(fetchedUsers)
        .then((result) => {
          const allSavedUsersFull = localStorage.allUsers ? JSON.parse(localStorage.allUsers) : []

          localStorage.allUsers = JSON.stringify([...allSavedUsersFull, ...result])

          this.finishInit = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    formatUser(id) {
      const allSavedUsers = localStorage.allUsers ? JSON.parse(localStorage.allUsers) : []
      const findById = allSavedUsers.find((user) => user.id === id)

      if (findById) return `${findById.name} - ${findById.status}`

      return ''
    },
    async fetchUser(id) {
      return new Promise((resolve, reject) => {
        fetchUserData(id)
          .then((res) => {
            resolve(res)
          })
          .catch(() => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(false)
          })
      })
    },
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.historyList) preFill.historyList = {
          search: ''
        }

        preFill.historyList.search = this.search

        localStorage.preFillData = JSON.stringify(preFill)
      }
    },
    async sendQuery(resetPage = true) {
      if (this.lock) return
      const obj = { options: this.options, search: this.search && this.search.length > 1 ? this.search : '' }

      if (this.type) {
        obj.type = this.type
      }

      if (this.dateFrom) {
        obj.dateFrom = this.dateFrom
      }

      if (this.dateTill) {
        obj.dateTill = this.dateTill
      }

      obj.resetPage = resetPage

      this.$emit('fetchHistories', obj)
      this.savePreFill()
    }
  }
}
</script>
